'use client';
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Box,
  Button,
  VStack,
  Icon,
  HStack,
  Link,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaYoutube
} from 'react-icons/fa';
import { useState, useEffect } from 'react';

import React from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/navigation';
import { motion,useAnimation } from 'framer-motion';

export default function MobileDrawer(props) {
  const [isOpen, setIsOpen] = useState(false);
  //const { isOpen, onOpen, onClose } = useDisclosure(false);
  const boxVariants = {
    hidden: { clipPath: 'circle(0% at 100% 0%)' },
    visible: { clipPath: 'circle(100% at 100% 0%)' },
  };
  const controls = useAnimation();

  useEffect(() => {
    if (isOpen) {
      controls.start({ x: 0, width: '100vw' });
    } else {
      controls.start({ x: '100%', width: 0 });
    }
  }, [isOpen, controls]);

  
  const iwinPhone=5491122604444;
  const message="Hola, me contacto desde su web...";
  const fullMessage = `${message} `;
  const whatsappUrl = `https://wa.me/${iwinPhone}?text=${encodeURIComponent(fullMessage)}`;



 
  const { push } = useRouter();
  return (
 
  

    <Box
    
      zIndex="9999"
      position={'relative'}>
      <Box  marginRight={'10px'}>
        <IconButton
        colorScheme='transparent'
          zIndex={20}
          icon={
            isOpen ? (
              <CloseButton
                fontSize={'20px'}
                color="white !important"
               
              />
            ) : (
              <HamburgerIcon
                color={props.burgerColor}//"black !important"
                fontSize={'30px'}
              
                
              />
            )
          }
          onClick={() => setIsOpen(!isOpen)}></IconButton>
      </Box>

<motion.div  
         style={{
            position: 'fixed',
            top: 0,
            right: 0,
            height: '500px',
            backgroundColor: '#E77800',
            zIndex: 19,
          }}
          initial={{ x: '100%', width: 0 }}
          animate={controls}
          transition={{ type: 'tween', duration: 0.3 }}>


      <Box
        zIndex={10}
        display={isOpen ? 'flex' : 'none'}
        flexDirection={'column'}
        justifyContent={'space-around'}
        position={'absolute'}
 
       
      
        width="100vw !important"
        height="500px"
        top='10px'
        right='-15px'
        color="white"
        textAlign="center"
        >
          <Box margin={'auto'} display={'flex'} flexDirection={'column'} lineHeight="20px" height={'450px'}  justifyContent={'space-evenly'} >

         
        <Button
          variant="nav"
          onClick={() => {
            push('/home');
          }}
          _hover={{ color: 'grey' }}>
          {' '}
          BUSCADOR
        </Button>

        <Button
          variant="nav"
          onClick={() => {
            push('/emprendimientos');
          }}
          _hover={{ color: 'grey' }}>
          {' '}
          EMPRENDIMIENTOS
        </Button>

        <Button
          variant="nav"
          onClick={() => {
            push('/inversiones');
          }}
          _hover={{ color: 'grey' }}>
          {' '}
          INVERSIONES
        </Button>

        <Button
          variant="nav"
          onClick={() => {
            push('/tasaciones');
          }}
          _hover={{ color: 'grey' }}>
          {' '}
          TASACIONES
        </Button>

        <Button
          variant="nav"
          onClick={() => {
            push('/empresa');
          }}
          _hover={{ color: 'grey' }}>
          {' '}
          EMPRESA
        </Button>

        <Button
          variant="nav"
          onClick={() => {
            push('/contacto-iw');
          }}
          _hover={{ color: 'grey' }}>
          {' '}
          CONTACTO
        </Button>

        <Box display={'flex'} justifyContent={'center'}>
          <Link
            _hover={{ color: 'grey' }}
            href="https://es-la.facebook.com/interwin.itw/"
            target="_blank"
            rel="noopener noreferrer">
            <Button variant="nav">
              <FaFacebook />
            </Button>
          </Link>
          <Link
            _hover={{ color: 'grey' }}
            href="https://www.instagram.com/interwin.itw/?hl=es"
            target="_blank"
            rel="noopener noreferrer">
            <Button variant="nav">
              <FaInstagram />
            </Button>
          </Link>
          <Link
            _hover={{ color: 'grey' }}
            href="https://ar.linkedin.com/company/interwin-marketing-inmobiliario"
            target="_blank"
            rel="noopener noreferrer">
            <Button variant="nav">
              <FaLinkedin />
            </Button>
          </Link>
          <Link
            _hover={{ color: 'grey' }}
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer">
          <Button variant="nav">
            <FaWhatsapp />
          </Button>
          </Link>
          <Link
            _hover={{ color: 'grey' }}
            href='https://www.youtube.com/interwinargentina'
            target="_blank"
            rel="noopener noreferrer">
            <Button variant="nav">
            <FaYoutube/>
            </Button>
          </Link>
          
          </Box>
        </Box>
      </Box>
      </motion.div>
    </Box>

  );
}
